//src views component report styled.ts
import styled from 'styled-components'

interface TableProps {
  marginTop?: string
  marginBottom?: string
}

interface IRowContainer {
  justifyContent?: string
}
interface IGraphicsContainer {
  width?: string
}

interface BackgroundColor {
  color?: string
}
interface ItemCount {
  active?: boolean
}
interface IItemDots {
  rotate?: string
  top?: string
  borderColor?: string
}
interface ILineGraphics {
  rotate?: string
  borderColor?: string
  width?: string
}
interface ILineGraphicsArea {
  width?: string
  right?: string
  top?: string
  bottom?: string
  angle?: string
  height?: string
  Bordercolor?: string
}

interface IProgressGraphic {
  width?: number
  backgroundColor?: string
  border?: string
  justifyContent?: string
  color?: string
  backgroundColorbar?: string
}
export const ContainerGlobal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  width: auto;
  height: 100%;
  padding: 10px;
  margin: auto;
  background-color: white;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  width: auto;
  height: 100%;
  margin: 0;
  background-color: white;
`

export const TitleWrapper = styled.div`
  width: 80%;
`

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  width: calc(100% - 48px);
`

export const CobrandingWrapper = styled.div`
  width: 12px;
`

export const InfoName = styled.span`
  font-family: 'Spartan-ExtraBold';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  text-align: justify;
`

export const InfoDate = styled.span`
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.1em;
`

export const PrincipalTitle = styled.h1`
  font-family: 'Spartan-ExtraBold';
  line-height: 1;
  letter-spacing: -0.05em;
  color: #c6016b;
  margin: 0;
  margin: 8px 0 8px 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  display: flex;
  align-items: center;
`

export const Page = styled.div<BackgroundColor>`
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 595px;
  max-width: 595px;
  height: 842px; /* Removed the condition as it was not necessary */
  min-height: 842px;
  max-height: 842px;
  background: ${(props) => props.color || '#fff'};
  margin: 0;
  padding: 0;
  .header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 60px;
    margin-top: 48px;
    -webkit-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
  h3 {
    font-family: 'Spartan-ExtraBold';
    line-height: 28px;
    letter-spacing: -0.07em;
    font-size: 45px;
    margin-top: 120px;
    margin-left: 24px;
    color: #2d749c;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 800;
    text-align: justify;
  }
  h5 {
    font-family: 'Lato-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0px;
    /* text-align: center; */
    margin-left: 24px;
    color: #1e1e1e;
    text-align: justify;
  }
  .footer_text {
    color: #1e1e1e;
    margin-top: 220px;
    margin-left: 24px;
  }
  .footer_page {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1e1e1e;
    margin: 5px;
    padding: 5px;
  }
  .semaforo {
    display: flex;
    width: 90%;
    height: auto;
    margin: 15px;
    justify-content: center;
    align-items: center;
  }
  p {
    font-family: 'Lato-Regular';
    width: 100%;
    margin-left: 10px;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: justify;
  }
  ul li {
    font-size: 13px;
    text-align: justify;
    width: 100%;
    font-family: 'Lato-Regular';
  }
  .progress-container {
    width: 25rem;
    height: 10px;
    background-color: #738c9a;
    position: relative;
    border-radius: 8rem;
  }

  .progress-bar {
    height: 10px;
    background-color: #738c9a;
    position: relative;
    border-radius: 8rem;
  }

  .progress-value {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    font-weight: bold;
    border: 2px solid green;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .dataDivider {
    border: 1px dashed #d2e3ec;
    transform: rotate(90deg);
  }
  .percentilData {
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    font-weight: bold;
    border: 2px dashed #d2e3ec;
    width: 40px;
    height: 40px;
    z-index: 1;
    position: relative;
    align-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 50%;
  }
  .percentilValue {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 12px;
    border-radius: 50%;
    padding: 10px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    z-index: 1;
  }
  div {
    font-family: 'Lato-Regular';
  }
  img .imgGray {
    filter: grayscale(1);
  }
`
export const TextBold = styled.b`
  font-family: 'Spartan-SemiBold';
  width: auto;
  margin-left: 10px;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  text-align: justify;
`

export const Cabecera = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 38px;
  margin-bottom: 10px;
  .title_header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: #c6016b;
    opacity: 0.8;
    width: 45%;
    height: 24px;
    -webkit-border-bottom-right-radius: 40% 100%;
    border-bottom-right-radius: 40% 100%;
    padding: 2px 0;
    p {
      font-family: 'Spartan-ExtraBold', sans-serif;
      font-style: normal;
      -webkit-letter-spacing: -0.07em;
      -moz-letter-spacing: -0.07em;
      -ms-letter-spacing: -0.07em;
      letter-spacing: -0.07em;
      color: white;
      font-size: 14px;
      margin: 0 auto;
      margin-left: 10px;
      text-align: justify;
      text-rendering: optimizeLegibility;
    }
  }
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 90%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1e1e1e;
  margin: 5px;
  margin-bottom: 10px;
  padding: 5px;
  border-top: 0.5px dashed #505050;
  /*#505050
 */
  p {
    font-family: 'Lato-Regular';
    margin: 5px;
    color: #505050;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
  }
  .count_page {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .count {
      background: #c6016b;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      margin: 1px;
    }
  }
`

export const Count = styled.div<ItemCount>`
  background: ${(props) => (props.active ? '#C6016B' : ' #e57db5')};
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin: 1px;
`

export const Tr = styled.tr`
  padding: 0;
  border: 0.5px solid #738c9a;
`
export const Td = styled.td`
  font-family: 'Lato-Regular';
  padding: 8px 0 !important;
  text-align: center;
  font-weight: bold;
  border: 0.5px solid #738c9a;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const Td2 = styled.td`
  font-family: 'Lato-Regular';
  padding: 8px;
  text-align: center;
  font-weight: bold;
  border: 0.5px solid #738c9a;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  text-align: left;
  padding-left: 16px;
  letter-spacing: -0.2px;
`
export const TdBorder = styled.td`
  padding: 15px;
  border: 1px solid;
`
export const Th = styled.th`
  font-family: 'Lato-Regular';
  padding: 0;
  border: 0.5px solid #738c9a;
  background: #f7f7f7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const SubtitleCaption = styled.caption`
  font-size: 'Spartan-Regular';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #2d749c;
  margin: 5px;
`

export const Title = styled.h2`
  font-family: 'Spartan-ExtraBold';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #c6016b;
  letter-spacing: -0.05em;
  margin-left: 24px;
  text-align: justify;
  margin: 15px;
`

export const P = styled.p<IGraphicsContainer>`
  font-family: 'Lato-Regular';
  width: ${(props) => (props.width ? props.width : '95%')} !important;
  margin: 1px;
  color: #1e1e1e !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
`

export const Caption = styled.caption`
  font-family: 'Spartan-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  text-align: left;
  color: #2d749c;
  margin: 10px;
  text-align: justify;
`
export const Table = styled.table`
  font-weight: 300;
  letter-spacing: 0.1em;
  border: 0.5px solid #738c9a;
  border-collapse: collapse;
  width: 100%;
  tr td {
    border: 0.5px solid #738c9a;
  }
`

export const ContainerScheme = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  background-color: #fff;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 240px;
  padding: 5px;
  margin: 15px;
`
export const ColumnTextLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-right: 1px solid #738c9a;
  width: 100px;
  height: 80%;
  padding: 5px;
  h4 {
    font-size: 'Spartan-Regular';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #738c9a;
  }
  p {
    font-size: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1e;
    margin: 1px;
  }
`
export const ColumnTextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid #738c9a;
  width: 100px;
  height: 80%;
  padding: 5px;
  h4 {
    font-size: 'Spartan-Regular';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #738c9a;
  }
  p {
    font-size: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #1e1e1e;
    margin: 1px;
  }
`
export const ColumnCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  padding: 5px;
  background: #edf1f3;
  border-radius: 50%;
  width: 170px;
  height: 60%;
  border: 1px solid #738c9a;
  text-align: center;
  h4 {
    font-size: 'Spartan-Regular';
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #738c9a;
  }
  p {
    font-size: 'Spartan-Regular';
    height: auto;
    width: 90%;
    font-size: 12px;
    line-height: 12px;
    color: #1e1e1e;
    margin: 1px;
  }
`
export const PreviewTable = styled.table`
  width: 70%;
  margin: 16px 15% 0 15%;
  align-self: center;
  border: 0;
  border-spacing: 0;
  border: 0.1px solid #738c9a;
  border-style: solid;
  border-radius: 4px;
  tr,
  th {
    border: 0.5px solid #738c9a;
  }
  td {
    border: 1px solid #738c9a;
  }
`

export const TableFooter = styled.div<TableProps>`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  margin-top: ${(props) => props.marginTop || '0px'};
  margin-bottom: 28px;
`

export const InnerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  padding: 0;
  margin: 0;

  tr {
    width: 100%;
  }
  td {
    width: 25%;
  }
  tr:first-child {
    border-top: none;
  }
  tr:last-child {
    border-bottom: none;
  }
`
export const InnerTableCell = styled.td`
  padding: 5px 5px;
  text-align: center;
  width: 100%;
`
export const TopInnerTableRow = styled.tr`
  padding: 0;
  td {
    border-top: none;
  }
`
export const BottomInnerTableRow = styled.tr`
  padding: 0;
  td {
    border-bottom: none;
  }
`
export const HeadingTable = styled.th`
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
  padding: 8px 0;
  text-align: center;
  width: 30%;
  background-color: #f6f6f6;
`
export const TableCell = styled.td`
  font-family: 'Lato-Regular';
  font-style: normal;
  font-size: 10px;
  font-weight: 800;
  padding: 8px 0;
  text-align: center;
  width: 50%;
`
export const CustomTableCell = styled.td`
  border: 1px solid red;
  padding: 0;
  text-align: center;
  width: 50%;
`

export const ContainerT = styled.div<TableProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 48px);
  height: calc(100% - 66px);
  justify-content: center;
  margin-top: -50px;
  padding: 0 24px;
`

export const FirstPageFooter = styled.div`
  width: 100%;
  height: 50px;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #1e1e1e;
  padding: 8px 0;
`

export const ContainerTable = styled.div<TableProps>`
  width: calc(100% - 32px);
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed #738c9a;
  border-radius: 8px;
`

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  background: #738c9a;
  border-radius: 7.56662px;
  color: white;
  width: 80%;
  margin: auto;
  padding: 3px;
`
export const Subtitle = styled.h2<TableProps>`
  font-family: 'Spartan-SemiBold';
  letter-spacing: -0.05em;
  color: #2d749c;
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
`

export const SubtitleGraphic = styled.h2`
  font-family: 'Spartan-SemiBold';
  letter-spacing: -0.05em;
  color: #2d749c;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.7px;
`

export const SubtitleTable = styled.h2`
  font-family: 'Lato-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const ContainerTextTableCenter = styled.div<IGraphicsContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: ${(props) => props.width || '80%'};
  p {
    margin: auto;
    width: auto;
    color: #98b06f;
    font-weight: 700;
  }
  .Subtitle {
    display: flex;
    font-family: 'Lato-Regular';
    margin: 1px;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
`
export const ContainerColumnGraphic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: transparent;
`

export const ContainerColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
`

export const ContainerRow = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  background-color: transparent;
  z-index: 1;
  .line {
    width: 100%;
    z-index: -1;
    top: 50%;
    position: absolute;
    height: 1px;
    background-color: #c6016b;
  }
`

export const Circle = styled.div<ItemCount>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  background: ${(props) => (props.active ? '#F5EBF1' : '#EAEDEE')};
  border-radius: 50%;
  width: 70px;
  height: 70px;
  border: 1px solid ${(props) => (props.active ? '#C6016B' : '#738C9A')};
  box-shadow: 0px 0px 4px 1px rgba(221, 122, 175, 0.46);
  h4 {
    font-size: 'Spartan';
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    color: #817c7c;
    text-align: center;
  }
`

/* border: 0.5px solid #C6016B;
background: #F5EBF1; */

export const TableQuadrants = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: 80px 80px 80px 80px;
  grid-row: auto auto;
  /*grid-column-gap: 20px;
  grid-row-gap: 20px;*/
`

export const GraphicsContainer = styled.div<IGraphicsContainer>`
  position: relative;
  background-color: white;
  width: ${(props) => props.width || '220px'};
  height: ${(props) => props.width || '220px'};
  margin: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  p {
    font-family: 'Lato-Regular';
    text-align: center;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
  }
  .line {
    position: absolute;
    width: 65%;
    height: 1px;
    border-bottom: 1px solid #f00101;
    z-index: 4;
    left: 10%;
    right: 0;
    top: 55%;
    bottom: 0;
    margin: auto;
    transform-origin: 0 0;
    transform: rotate(5deg);
  }
  .line2 {
    position: absolute;
    width: 30%;
    height: 1px;
    border-bottom: 1px solid #f00101;
    z-index: 4;
    left: 0%;
    right: -8.5%;
    top: 0;
    bottom: 23%;
    margin: auto;
    transform-origin: 0 0;
    transform: rotate(349deg);
  }
  .line3 {
    position: absolute;
    width: 1px;
    height: 42.7%;
    border-right: 1px solid #f00101;
    z-index: 7;
    left: 0%;
    right: 20%;
    top: 22%;
    bottom: 0%;
    margin: auto;
    transform-origin: 0% 0%;
    transform: rotate(25deg);
  }
`

export const LineGraphicsArea = styled.div<ILineGraphicsArea>`
  position: absolute;
  width: ${(props) => props.width || '0'};
  height: 1px;
  border-bottom: 1px solid ${(props) => props.Bordercolor || '#F00101'};
  z-index: 4;
  left: 0;
  right: ${(props) => props.right || '0'};
  top: ${(props) => props.top || '0'};
  bottom: 0;
  margin: auto;
  transform-origin: 0 0;
  transform: rotate(${(props) => props.angle || '0'});
`

export const LineGraphicsArea2 = styled.div<ILineGraphicsArea>`
  position: absolute;
  width: ${(props) => props.width || '1px'};
  height: ${(props) => props.height || '1px'};
  border-right: 1px solid ${(props) => props.Bordercolor || '#F00101'};
  z-index: 4;
  left: 0;
  right: ${(props) => props.right || '0'};
  top: ${(props) => props.top || '0'};
  bottom: ${(props) => props.bottom || '0'};
  margin: auto;
  transform-origin: 0 0;
  transform: rotate(${(props) => props.angle || '0'});
`
export const LineGraphicsFrontLeft = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
`
export const LineGraphicsFrontRight = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
  right: 0px;
`
export const LineGraphicsBasalRight = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
  right: 0px;
  bottom: 0px;
`
export const LineGraphicsBasalLeft = styled.div<ILineGraphics>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '30px'};
  height: ${(props) => props.width || '30px'};
  border-radius: 50%;
  border: 1px dashed ${(props) => props.borderColor || '#F00101'};
  background-color: transparent;
  z-index: 4;
  position: absolute;
  left: 0px;
  bottom: 0px;
`

export const ContainerNameProfile = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  height: 24px;
  padding: 10px;
  gap: 8px;
  margin: 5px;
  background: #d2e3ec;
  .lineColorProfile {
    color: #c6016b;
    width: 30%;
    img {
      object-fit: contain;
    }
  }
  .lineColorIdealProfile {
    color: #2d749c;
    width: 30%;
    img {
      object-fit: contain;
    }
  }
`

export const ContainerDots = styled.div`
  position: absolute;
  z-index: 3;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const ItemDotsContainer = styled.div<IItemDots>`
  width: 35%;
  height: 1px;
  border-bottom: 1px dashed ${(props) => props.borderColor || '#F00101'};
  z-index: 4;
  margin-top: 50px;
  transform: rotate(${(props) => props.rotate || 0}deg);
  margin-top: ${(props) => props.top || 50}px;
  margin-left: 20px;
`

export const RoundedDotedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px dashed #f00101;
  background-color: transparent;
  z-index: 4;
  position: absolute;
`

export const RowContainer = styled.div<IRowContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  width: 100%;
  height: 60px;
`
export const StaticGraphicDiv = styled.div`
  position: absolute;
  top: -50px;
  left: 380px;
  width: 125px;
  height: 125px;
  img {
    width: 125px;
    height: 125px;
  }
`

export const ProgressGraphicContainer = styled.div<IProgressGraphic>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /*    gap: 20px; */
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : null)};
`
export const ProgressContainer = styled.div<IProgressGraphic>`
  width: 25rem;
  height: 10px;
  background-color: ${(props) => (props.backgroundColorbar ? props.backgroundColorbar : null)};
  position: relative;
  border-radius: 8rem;
`
export const ProgressBar = styled.div<IProgressGraphic>`
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  height: 10px;
  background-color: ${(props) => (props.backgroundColorbar ? props.backgroundColorbar : '#738C9A')};
  position: relative;
  border-radius: 8rem;
  z-index: 2;
`
export const ProgressValue = styled.div<IProgressGraphic>`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : '#ffffff')};
  border-radius: 50%;
  padding: 10px;
  font-weight: bold;
  border: ${(props) => (props.border ? `2px solid ${props.border}` : '2px solid green')};
  color: ${(props) => (props.color ? `${props.color}` : '#000000')};
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`
export const DataDivider = styled.hr`
  border: 1px dashed #d2e3ec;
  width: 90px;
  z-index: 1;
`
export const PercentilData = styled.div<IProgressGraphic>`
  background-color: white;
  border-radius: 50%;
  font-weight: bold;
  border: 2px dashed #d2e3ec;
  z-index: 2;
  position: relative;
  align-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 20%;
`
export const PercentilValue = styled.div<IProgressGraphic>`
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 12px;
  border-radius: 50%;
  padding: 10px;
  font-weight: bold;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 1;
`
export const ExpectedScore = styled.div<IProgressGraphic>`
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  height: 10px;
  background-color: #d2e3ec;
  position: relative;
  border-radius: 8rem;
`
export const UserScore = styled.div<IProgressGraphic>`
  width: ${(props) => (props.width ? `${props.width}%` : '100%')};
  height: 10px;
  background-color: #d2e3ec;
  position: relative;
  border-radius: 8rem;
`
export const ExpectedScoreValue = styled.div<IProgressGraphic>`
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  background-color: #2d749c;
  border-radius: 50%;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`
export const UserScoreValue = styled.div<IProgressGraphic>`
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  background-color: #c6016b;
  border-radius: 50%;
  font-weight: bold;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`
export const GapContainer = styled.div`
  position: absolute;
  /*  right: -10px; */
  top: -30px;
  color: black;
`
export const EficientLabel = styled.div`
  z-index: 2;
  padding: 5px 10px;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #c6016b;
  border-radius: 8px;
  font-size: 10px;
  width: max-content;
`
export const StructureImg = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 40%;
  right: 40%;
  margin: auto;
`

export const FrontLeftCopy = styled.div`
  font-family: 'Spartan-SemiBold' !important;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  text-transform: uppercase;
  top: 20px;
  left: -70px;
  text-align: center;
  line-height: 0.5%;
  p {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 10px;
    font-weight: 900;
    color: #2d749c;
    line-height: 1;
  }
  span {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    color: #2d749c;
    line-height: 1.6;
  }
`
export const FrontRightCopy = styled.div`
  font-family: 'Spartan-SemiBold';
  align-content: center;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  top: 20px;
  right: -70px;
  text-transform: uppercase;
  line-height: 0%;
  p {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 10px;
    font-weight: 900;
    color: #2d749c;
    line-height: 1;
  }
  span {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    color: #2d749c;
    line-height: 1.6;
  }
`
export const BasalRightCopy = styled.div`
  font-family: 'Spartan-SemiBold';
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  right: -70px;
  bottom: 40px;
  text-transform: uppercase;
  line-height: 0%;
  p {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 10px;
    font-weight: 900;
    color: #2d749c;
    line-height: 1;
  }
  span {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    color: #2d749c;
    line-height: 1.6;
  }
`
export const BasalLeftCopy = styled.div`
  font-family: 'Spartan-SemiBold';
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 4;
  position: absolute;
  left: -70px;
  bottom: 40px;
  text-transform: uppercase;
  line-height: 0%;
  p {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 10px;
    font-weight: 900;
    color: #2d749c;
    line-height: 1;
  }
  span {
    font-family: 'Spartan-ExtraBold';
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    color: #2d749c;
    line-height: 1.6;
  }
`
export const BetesaQuadrantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 270px;
  padding-top: 10px;
  padding-bottom: 0%;
`

export const TextTitleAlertBetesa = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 12px;
  margin-top: 15px;
  color: #1f2124;
  align-self: center;
`

export const WrapperPriority = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const WrapperAlertLevelText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const HeaderFirstPage = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(595px - 20px);
  height: 75px;
  margin: 48px 0 20px 0;
  padding-right: 20px;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  overflow: hidden;
`

export const Description = styled.div`
  font-family: 'Lato';
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 10px;
`

export const FirstPageImage = styled.img`
  display: block;
  max-width: 100%;
`

export const FooterText = styled.div`
  font-family: 'Spartan';
  font-size: 14px;
  font-weight: 600;
  line-height: 15.68px;
  letter-spacing: 0.07em;
  color: #738c9a;
  text-align: center;
`
