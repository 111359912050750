import { useState, useEffect } from 'react'
import { MainLayout } from 'views/layouts'
import { userSelector } from 'ducks/user'
import { useSelector } from 'react-redux'
import { HEADERS, downloadHeader, handleFetchError } from './constants'
import { ActionViewDownloadReportSpaceUserEvaluated } from 'views/components/UI/BluesiteTable/Actions/GridActions'
import { SelectedItemsProvider } from './context/context'
import { BoldText, TopContainer, Container } from './styled'
import Select from 'views/components/UI/Select'
import BluesiteTableComponent from 'views/components/UI/BluesiteTable'
import { useFetchAllProcesses } from 'hooks/process/useGetAllProcessCurrentCompany/useGetAllProcessCurrentCompany'
import { useGetResumeProcess } from 'hooks/process/useGetResumeProcess/useGetResumeProcess'
import ModalLoader from 'views/components/UI/ModalLoader/modal-loader'
import { useNotification } from 'lib/context/notification.context'
import { getProcessReports } from 'services/common/reports/getProcessReport/get-process-reports-service'
import { getUsersByCompany } from './services/getUsersByCompany'

const SpaceEvaluatedUsers = () => {
  const [tableData, setTableData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const { companyId } = useSelector(userSelector)
  const { allProcess, fetchAllProcesses, loadingAllProcesses } = useFetchAllProcesses()
  const [selectedItems, setSelectedItems] = useState(new Set<number>())
  const [process, setProcess] = useState<number | ''>('')
  const [searchParams, setSearchParams] = useState('')
  const [triggerApi, setTriggerApi] = useState(false)
  const [loadingReports, setLoadingReports] = useState(false)
  const [totalResults, setTotalResults] = useState(0)
  const { loadingDetailProcess, fetchProcessData } = useGetResumeProcess()
  const { getWarning, getError } = useNotification()

  useEffect(() => {
    if (process === '' || process === 0) {
      fetchAllProcesses()
    } else {
      fetchProcessData(process)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process])

  useEffect(
    () => {
      const body: { page: number; companyId: number; search?: string; processId?: number } = {
        page: currentPage,
        companyId,
      }
      if (searchParams) {
        body.search = searchParams
      }
      if (process) {
        body.processId = process
      }
      getUsersByCompany(body)
        .then((response) => {
          if (!response) {
            setTotalPage(0)
            setTableData([])
            throw new Error('Error in the request')
          }

          const textType = response?.response?.map((item: any) => {
            return {
              document: item.document,
              mail: item.email,
              name: item.first_name + ' ' + item.last_name,
              process: item.process_ids,
              id: item.userId,
              complete: item.complete,
            }
          })

          setTotalPage(response.total_pages)
          setTableData(textType)
          setTotalResults(response.total_results)
        })
        .catch((error) => {
          setTotalPage(0)
          setTableData([])
          handleFetchError(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId, currentPage, process, searchParams, triggerApi]
  )

  const handleSelectChange = (e: any) => {
    setCurrentPage(1)
    if (e) {
      setProcess(parseInt(e))
    } else {
      setProcess('')
    }
  }

  const handleSelectClick = () => {
    if (allProcess.length === 0) {
      getWarning('No tienes procesos asignados')
    }
  }

  const handleSearch = (search: string) => {
    setSearchParams(search.trim())
    if (!search) {
      setTriggerApi((prev) => !prev)
      return
    }
    setCurrentPage(1)
  }

  const onDownload = async (id: number) => {
    setLoadingReports(true)

    let body

    if (process !== '' && process !== 0) {
      body = {
        users: [{ id: id }],
        process_id: process,
      }
    } else {
      body = {
        users: [{ id: id }],
      }
    }

    try {
      const response: any = await getProcessReports(body)

      if (!response || response.status === 'error') {
        getError('No se pudo descargar el reporte')
        setLoadingReports(false)
        return
      }

      const dateZip = new Date()
      const dia = dateZip.getDate()
      const mes = dateZip.getMonth() + 1
      const año = dateZip.getFullYear()
      const url = window.URL.createObjectURL(response)

      const a = document.createElement('a')

      a.href = url

      a.download = `Usuarios_evaluados_${dia}_${mes}_${año}.zip`

      setLoadingReports(false)
      a.click()

      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <MainLayout>
      {loadingReports && (
        <ModalLoader
          style={{ backgroundColor: 'white', padding: '30px', borderRadius: '30px' }}
          text="Generando reportes..."
          isLoading={loadingReports}
        />
      )}
      <Container>
        <h2>Espacio de evaluados con cuentas activas</h2>
        {loadingAllProcesses || loadingDetailProcess ? (
          <ModalLoader isLoading={loadingAllProcesses || loadingDetailProcess} />
        ) : (
          <>
            <TopContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  width: 430,
                  alignItems: 'center',
                  marginTop: 0,
                  marginBottom: 30,
                }}
              >
                <BoldText>Visualizar:</BoldText>
                <Select
                  label="Todos los procesos"
                  options={allProcess}
                  value={process}
                  onChange={(e) => handleSelectChange(e)}
                  onClick={() => handleSelectClick()}
                />
              </div>
            </TopContainer>
            <SelectedItemsProvider selectedItems={selectedItems}>
              <BluesiteTableComponent
                data={tableData}
                headers={selectedItems.size > 1 ? HEADERS : [...HEADERS, downloadHeader]}
                searchBar={true}
                onSearch={handleSearch}
                setSearchParam={handleSearch}
                ActionComponent={(props) => (
                  <ActionViewDownloadReportSpaceUserEvaluated
                    {...props}
                    complete={props.complete}
                    handleDownload={onDownload}
                  />
                )}
                searchLabel=""
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                totalPages={tableData?.length > 0 ? totalPage : 0}
                handlePagination={setCurrentPage}
                currentPage={currentPage}
                checks={false}
                showNumbers={true}
                totalItems={totalResults}
                renderAdditionalColumn={() => (
                  <></>
                  // <RowContainer>
                  //   <DownloadIcon />
                  //   <DownloadButton
                  //     onClick={() => {
                  //       alert('Acción de descargar el reporte de este usuario')
                  //     }}
                  //   >
                  //     Descargar reportes
                  //   </DownloadButton>
                  // </RowContainer>
                )}
              />
            </SelectedItemsProvider>
          </>
        )}
      </Container>
    </MainLayout>
  )
}

export default SpaceEvaluatedUsers
