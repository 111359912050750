import axios from 'lib/axios'
import { GET_USER } from '../constants'

export interface ParamsProps {
  page: number
  companyId: number
  processId?: number
  search?: string
}

export const getUsersByCompany = async ({ page, companyId, processId, search }: ParamsProps) => {
  try {
    const queryParams = new URLSearchParams()

    if (page) queryParams.append('page', page.toString())
    if (companyId) queryParams.append('company_id', companyId.toString())
    if (processId) queryParams.append('process_id', processId.toString())
    if (search) queryParams.append('search', search.toString())

    const response = await axios.get(`${GET_USER}/company?${queryParams.toString()}`)
    return {
      response: response.data.users,
      total_pages: response.data.total_pages,
      total_results: response.data.total_results,
    }
  } catch (error) {
    throw new Error('Error fetching actions')
  }
}
