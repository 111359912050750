import {
  Page,
  Td,
  Td2,
  Th,
  Tr,
  Table,
  Container,
  ContainerScheme,
  ContainerColumn,
  SubtitleTable,
  P,
  ContainerRow,
  ContainerNameProfile,
  ContainerTextTableCenter,
  BetesaQuadrantsContainer,
  TextTitleAlertBetesa,
  SubtitleGraphic,
  WrapperPriority,
} from './styled'
import React, { FC } from 'react'
import BackgrounPageClear from 'assets/images/BlusiteLogo.png'
import BackgrounPageText from 'assets/images/BlusiteText.png'
import Graphic from 'views/components/assets/images/graphic.png'
import Rectangle from 'views/components/assets/images/rectangle.png'
import { GraphicPolar } from './GraphicPolar'
import { EfficientGraphic } from './efficientGraphic'
import './Spartan-ExtraBold-normal'
import './Lato-Regular-normal'
import './Spartan-SemiBold-normal'
import './Spartan-Regular-normal'
import Headpage from './components/header'
import AlertCircle from './components/alert-level-circle'
import CartesianAxisBrainQuadrant from 'views/components/CartesianAxisBrainQuadrant'
import FirstPage from './components/betesa/first-page/first-page'
import DescriptionPage from './components/betesa/description-page/description-page'

import {
  GraphText,
  WrapperText,
  Subtitle,
  AlertLevelText,
  AlertLevelTextBold,
  EmotionalStateText,
  StatesDiv,
  TitleText,
  QuadrantWrapper,
  SubtitleQuadrant,
  QuadrantText,
  AdjustmentLevelTitle,
  AdjustmentLevelText,
  TitleWrapper,
  ScaleContainer,
  Scale,
  ItemScale,
  ItemScaleText,
  GraphTextMargin,
  SubtitleQuadrantMargin,
  Stressors,
} from './components/betesa/description-page/styled'
import parse from 'html-react-parser'
import FooterPage from './components/footer-page'
import transformToCartesianData from 'utils/transformToCartesianData'
import { useTranslation } from 'react-i18next'

const NameQuadrants = (name: string) => {
  if (name === 'FI') return 'global.common.betesa.quadrants.FI'
  if (name === 'FD') return 'global.common.betesa.quadrants.FD'
  if (name === 'BI') return 'global.common.betesa.quadrants.BI'
  if (name === 'BD') return 'global.common.betesa.quadrants.BD'
}
const NameLevelAlert = (name: string) => {
  if (name === 'E') return 'global.common.alertLevels.E'
  if (name === 'EI') return 'global.common.alertLevels.EI'
  if (name === 'EE-II') return 'global.common.alertLevels.EE-II'
  if (name === 'IE') return 'global.common.alertLevels.IE'
  if (name === 'I') return 'global.common.alertLevels.I'

  return 'global.common.alertLevels.I'
}

const calculatePages = (Quadrants: any, ProfileIdealGlobal: any) => {
  let pages = 5
  if (Quadrants?.length > 1) {
    pages += 1
  }
  if (ProfileIdealGlobal && Object.keys(ProfileIdealGlobal)?.length > 0) {
    pages += 1
  }
  return pages
}

interface ReportBetesaSchemaProps {
  data: any
  dominationData?: any
  isAdmin?: boolean
  lang? : string
}

export const ReportBetesaSchema: FC<ReportBetesaSchemaProps> = ({ data, dominationData, isAdmin, lang }) => {
  const { t } = useTranslation()
  const {
    User,
    ResultFirstPart,
    introduction,
    AlertText,
    AlertLevel,
    QuadrantGlobal,
    QuadrantDomination,
    LeastEfficientQuadrant,
    Quadrants,
    ProfileIdealGlobal,
    StressorsAnswers,
    emocionalStateString,
    stressorsScore,
    answer_date: answerDate,
    emotional_state: EmotionalState,
    negative_adjectives_value: negativeAdjectivesValue,
    positive_adjectives_value: positiveAdjectivesValue,
    process_name: processName,
    adjustment_level: adjustmentLevel,
    adjustment_level_score: adjustmentLevelScore,
    idealProfileInfo,
    domination,
  } = data
  let itemWithPriorityOne
  if (isAdmin) {
    for (const item in idealProfileInfo) {
      if (idealProfileInfo[item].priority === 1) {
        itemWithPriorityOne = item
        break
      }
    }
  }

  const totalPages = calculatePages(Quadrants, ProfileIdealGlobal)
  console.log('ProfileIdealGlobal', ProfileIdealGlobal)
  let currentPage = 1

  const cartesianData = idealProfileInfo ? transformToCartesianData(idealProfileInfo) : undefined

  const dominationQuadrant = !dominationData ? domination : dominationData

  const RESULT_ORDER = [
    t('reportBetesa.chapters.chapter1Name'),
    t('reportBetesa.chapters.chapter2Name'),
    t('reportBetesa.chapters.chapter3Name'),
    t('reportBetesa.chapters.chapter4Name'),
  ]

  ResultFirstPart.sort((a: any, b: any) => {
    return RESULT_ORDER.indexOf(a.chapter_name) - RESULT_ORDER.indexOf(b.chapter_name)
  })

  return (
    <Container>
      <FirstPage
        answerDate={answerDate}
        introduction={introduction}
        User={User}
        domination={domination}
        AlertLevel={AlertLevel}
        QuadrantGlobal={QuadrantGlobal}
        stressorsScore={stressorsScore}
        emocionalStateString={emocionalStateString}
        positiveAdjectivesValue={positiveAdjectivesValue}
        negativeAdjectivesValue={negativeAdjectivesValue}
      />

      <DescriptionPage
        answerDate={answerDate}
        introduction={introduction}
        User={User}
        currentPage={currentPage}
        totalPages={totalPages}
        lang={lang}
      />

      <Page>
        <Headpage isBetesa={true} marginTop={0} />
        <ScaleContainer>
          <Scale>
            <p style={{ marginLeft: '6px', width: '70%' }}>0</p>
            <p>40</p>
            <p style={{ marginLeft: '8px', width: '50%' }}>60</p>
            <p style={{ marginRight: '-62px', marginLeft: '50px' }}>80</p>
            <p style={{ marginRight: '-72px', marginLeft: '60px' }}>100</p>
            <p style={{ textAlign: 'end' }}>+100</p>
          </Scale>
          <Scale>
            <ItemScale color="#F02401" style={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }}>
              <ItemScaleText color="#F02401">{t('global.common.colorGraphText.lowLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#FF8402">
              <ItemScaleText color="#FF8402">{t('global.common.colorGraphText.lowAvgLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#674531">
              <ItemScaleText color="#674531">{t('global.common.colorGraphText.highAvgLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#2C9430">
              <ItemScaleText color="#2C9430">{t('global.common.colorGraphText.highLevel')}</ItemScaleText>
            </ItemScale>
            <ItemScale color="#9E9E9E" style={{ borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }}>
              <ItemScaleText color="#9E9E9E">{t('global.common.colorGraphText.veryHighLevel')}</ItemScaleText>
            </ItemScale>
          </Scale>
        </ScaleContainer>
        {/* <RangeScoreBetesaImg /> */}
        <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '-25px', width: '100%', gap: 90 }}>
          <EfficientGraphic width="160px" Profile={QuadrantGlobal} dominationData={dominationQuadrant} />
          <GraphicPolar
            width="160px"
            simple={true}
            showQuadrantName={true}
            showScore={true}
            Profile={QuadrantGlobal}
            dominationData={dominationQuadrant}
          />
        </div>
        <WrapperText>
          <GraphText>{parse(introduction?.introduction_score)}</GraphText>
          <Subtitle>{t('betesaReport.developmentScoresTitle')}</Subtitle>
        </WrapperText>
        <ContainerRow>
          {ResultFirstPart.map((result: any) =>
            ResultFirstPart.indexOf(result) > 0 ? (
              <div style={{ background: '#fff' }} key={result.chapter_name}>
                <GraphicPolar
                  width="160px"
                  name={result.chapter_name}
                  showQuadrantName={false}
                  Profile={result}
                  dominationData={dominationQuadrant}
                />
              </div>
            ) : (
              <></>
            )
          )}
        </ContainerRow>
        <FooterPage
          name={User?.first_name}
          lastName={User?.last_name}
          page={++currentPage + 1}
          totalPages={totalPages}
          answerDate={answerDate}
          language={lang}
        />
      </Page>
      <Page>
        <Headpage isBetesa={true} marginTop={0} />
        <WrapperText>
          <Subtitle>{t('betesaReport.tableSummaryResults.alertLevel')}</Subtitle>

          <GraphText>{parse(introduction.introduction_alert_level)}</GraphText>
          <ContainerScheme style={{ alignItems: 'center', marginLeft: 0 }}>
            <AlertLevelText>
              {t('betesaReport.yourAlertLevel')}
              <AlertLevelTextBold>{t(NameLevelAlert(AlertLevel))}</AlertLevelTextBold>
            </AlertLevelText>
          </ContainerScheme>
        </WrapperText>

        <ContainerRow>
          <AlertCircle userAlertLevelName={AlertLevel} />
          <div className="line"></div>
        </ContainerRow>

        <WrapperText>
          <GraphText>{parse(String(AlertText[0]?.description))}</GraphText>
          <Subtitle>{t('betesaReport.moodCondition')}</Subtitle>
          <GraphText>{introduction?.introduction_recent_mood}</GraphText>
        </WrapperText>

        <ContainerRow>
          <ContainerColumn>
            <ContainerTextTableCenter>
              <SubtitleTable style={{ color: 'black' }}>{t('betesaReport.lastYearMoodCondition')}</SubtitleTable>
              <EmotionalStateText>{emocionalStateString}</EmotionalStateText>
            </ContainerTextTableCenter>

            <Table summary="Resumen de Resultados">
              <thead>
                <Tr>
                  <Th scope="col">{t('betesaReport.positiveAdjectives')}</Th>
                  <Th scope="col">{t('betesaReport.negativeAdjectives')}</Th>
                </Tr>
              </thead>
              <tbody>
                <Tr>
                  <Td>{EmotionalState?.positive_adjectives.score}</Td>
                  <Td>{EmotionalState?.negative_adjectives.score}</Td>
                </Tr>
                <Tr>
                  <Td2>
                    {EmotionalState?.positive_adjectives?.adjectives
                      .filter((a: string) => a !== t('betesaReport.noneOfTheAbove'))
                      .map((adjective: string) => (
                        <StatesDiv key={`${adjective}${Math.random().toString(36).substring(7)}`}>
                          {adjective} <br />
                        </StatesDiv>
                      ))}
                  </Td2>
                  <Td2>
                    {EmotionalState?.negative_adjectives?.adjectives
                      .filter((a: string) => a !== t('betesaReport.noneOfTheAbove'))
                      .map((adjective: string) => (
                        <>
                          <StatesDiv key={`${adjective}${Math.random().toString(36).substring(2)}`}>
                            {adjective} <br />
                          </StatesDiv>
                        </>
                      ))}
                  </Td2>
                </Tr>
              </tbody>
            </Table>
          </ContainerColumn>
          <ContainerColumn>
            <ContainerTextTableCenter>
              <SubtitleTable style={{ color: 'black' }}>{t('betesaReport.stressorsExposure')}</SubtitleTable>
              <p>{`${stressorsScore}/100`} </p>
            </ContainerTextTableCenter>
            <Table summary="Resumen de Resultados">
              <thead>
                <Tr>
                  <Th scope="col">{t('betesaReport.stressors')}</Th>
                </Tr>
              </thead>
              <tbody>
                <Tr>
                  <Td2>
                    {StressorsAnswers.filter((a: string) => a !== t('betesaReport.noneOfTheAbove')).map(
                      (stressor: string) => (
                        <div key={`${stressor}${Math.random().toString(36).substring(7)}`}>
                          <Stressors>{stressor}</Stressors>
                        </div>
                      )
                    )}
                  </Td2>
                </Tr>
              </tbody>
            </Table>
          </ContainerColumn>
        </ContainerRow>
        <FooterPage
          answerDate={answerDate}
          name={User.first_name}
          lastName={User.last_name}
          page={++currentPage + 1}
          totalPages={totalPages}
          language={lang}
        />
      </Page>
      <Page>
        <Headpage isBetesa={true} marginTop={0} />
        <WrapperText>
          <TitleText>{t('betesaReport.interpretativeReportTitle')}</TitleText>
          <GraphTextMargin>{QuadrantDomination?.dominance[0]?.description}</GraphTextMargin>
          <SubtitleQuadrantMargin>{t('betesaReport.someAptitudeFor')}</SubtitleQuadrantMargin>
          <QuadrantWrapper>
            {QuadrantDomination?.aptitudes?.map(({ description }: any) => (
              <li key={`${description}${Math.random().toString(36).substring(7)}`}>
                <P>{description}</P>
              </li>
            ))}
          </QuadrantWrapper>
          {Quadrants && Quadrants.length > 0 && (
            <SubtitleQuadrant>{t('betesaReport.someAptitudeFor80')}</SubtitleQuadrant>
          )}

          {Quadrants?.map((quadrant: any) => {
            return (
              <React.Fragment key={Math.random().toString(36).substring(7)}>
                <QuadrantText>{t(NameQuadrants(quadrant?.dominance[0]?.name) ?? '')}</QuadrantText>
                <QuadrantWrapper>
                  {quadrant?.aptitudes?.map(({ description }: any) => (
                    <li key={`${description}${Math.random().toString(36).substring(7)}`}>
                      <P>{description}</P>
                    </li>
                  ))}
                </QuadrantWrapper>
              </React.Fragment>
            )
          })}
          {Quadrants.length > 1 ? (
            <></>
          ) : (
            <>
              <SubtitleQuadrant>{t('betesaReport.moreSpendEnergy')}</SubtitleQuadrant>
              <QuadrantText>{t(NameQuadrants(LeastEfficientQuadrant?.dominance[0].name) ?? '')}</QuadrantText>
              <QuadrantWrapper>
                {LeastEfficientQuadrant?.challenge.map(({ description }: any) => (
                  <li key={`${description}${Math.random().toString(36).substring(7)}`}>
                    <P>{description}</P>
                  </li>
                ))}
              </QuadrantWrapper>
            </>
          )}
        </WrapperText>

        {(LeastEfficientQuadrant.challenge.length === 1 && Quadrants.length === 0) ||
          (Quadrants.length >= 2 && LeastEfficientQuadrant.challenge.length >= 1) ||
          (LeastEfficientQuadrant.challenge.length <= 5 &&
            QuadrantDomination.aptitudes.length > 0 &&
            Quadrants.length === 0 && (
              <div style={{ margin: 'auto', width: '90%', textAlign: 'center' }}>
                <img
                  width="90%"
                  height="auto"
                  style={{ margin: 'auto', opacity: '0.2', marginBottom: '20px', display: 'block' }}
                  src={BackgrounPageClear}
                  alt="background clear page"
                />
                {Object.keys(ProfileIdealGlobal).length > 1 && isAdmin && (
                  <img
                    width="90%"
                    height="auto"
                    style={{ opacity: '0.2', display: 'block', margin: 'auto' }}
                    src={BackgrounPageText}
                    alt="background text page"
                  />
                )}
              </div>
            ))}
        <FooterPage
          name={User?.first_name}
          lastName={User?.last_name}
          page={++currentPage + 1}
          totalPages={totalPages}
          answerDate={answerDate}
          language={lang}
        />
      </Page>

      {Quadrants.length > 1 && (
        <Page>
          <Headpage isBetesa={true} marginTop={0} />
          <WrapperText>
            <SubtitleQuadrant>{t('betesaReport.moreSpendEnergy')}</SubtitleQuadrant>
            <QuadrantText>{t(NameQuadrants(LeastEfficientQuadrant?.dominance[0].name) ?? '')}</QuadrantText>
            <QuadrantWrapper>
              {LeastEfficientQuadrant?.challenge.map(({ description }: any) => (
                <li key={`${description}${Math.random().toString(36).substring(7)}`}>
                  <P>{description}</P>
                </li>
              ))}
            </QuadrantWrapper>
          </WrapperText>

          <div style={{ margin: 'auto', width: '90%', textAlign: 'center' }}>
            <img
              width="90%"
              height="auto"
              style={{ margin: 'auto', opacity: '0.2', marginBottom: '20px', display: 'block' }}
              src={BackgrounPageClear}
              alt="background clear page"
            />
            {Object.keys(ProfileIdealGlobal).length > 1 && isAdmin && (
              <img
                width="90%"
                height="auto"
                style={{ opacity: '0.2', display: 'block', margin: 'auto' }}
                src={BackgrounPageText}
                alt="background text page"
              />
            )}
          </div>
          {ProfileIdealGlobal.length > 0 ? (
            <p
              style={{
                color: '#738C9A',
                fontSize: 24,
                alignContent: 'center',
                fontWeight: 400,
                position: 'absolute',
                bottom: '23%',
                left: '20%',
              }}
            >
              el reporte continúa en la siguiente página
            </p>
          ) : null}
          <FooterPage
            name={User?.first_name}
            lastName={User?.last_name}
            page={++currentPage + 1}
            totalPages={totalPages}
            answerDate={answerDate}
            language={lang}
          />
        </Page>
      )}

      {Object.keys(ProfileIdealGlobal).length > 1 && isAdmin && (
        <Page>
          <Headpage isBetesa={true} marginTop={0} />
          <WrapperText>
            <TitleText>{t('betesaReport.adjustmentLevelPage.title')}</TitleText>
            <TitleWrapper>
              <AdjustmentLevelTitle>{t('betesaReport.adjustmentLevelPage.subtitle')} {processName}: </AdjustmentLevelTitle>
              <AdjustmentLevelText>
                {adjustmentLevel} {adjustmentLevelScore ? '-' : ''} {adjustmentLevelScore}
                {adjustmentLevelScore ? '%' : ''}
              </AdjustmentLevelText>
            </TitleWrapper>
            <ContainerRow style={{ margin: '-20px 0px' }}>
              <GraphicPolar
                width="180px"
                simple={true}
                showQuadrantName={false}
                Profile={QuadrantGlobal}
                IdealProfile={ProfileIdealGlobal}
                dominationData={dominationQuadrant}
              />
            </ContainerRow>
            <ContainerRow>
              <ContainerNameProfile>
                <P>{t('betesaReport.adjustmentLevelPage.evaluatedProfileLegend')}</P>
                <div className="lineColorProfile">
                  <img src={Graphic} style={{ width: 25, height: 25, paddingTop: 7 }} alt="" />
                </div>
              </ContainerNameProfile>
              <ContainerNameProfile>
                <P>{t('betesaReport.adjustmentLevelPage.idealProfileLegend')}</P>
                <div className="lineColorIdealProfile">
                  <img src={Rectangle} style={{ width: 25, height: 25, paddingTop: 7 }} alt="" />
                </div>
              </ContainerNameProfile>
            </ContainerRow>
            <ContainerRow style={{ justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 5, paddingTop: 0, justifyContent: 'center' }}
              >
                <SubtitleGraphic style={{ fontSize: 12 }}>{t('betesaReport.adjustmentLevelPage.thinkingStructureLegend')}</SubtitleGraphic>
                <BetesaQuadrantsContainer>
                  <TextTitleAlertBetesa>{t('betesaReport.adjustmentLevelPage.levelAlertLegend')} {ProfileIdealGlobal?.alert_level}</TextTitleAlertBetesa>
                  {cartesianData && <CartesianAxisBrainQuadrant data={cartesianData} isReport={true} />}
                  <ContainerTextTableCenter style={{ marginLeft: '-30px', marginBottom: 15 }} width="80%">
                    <WrapperPriority>
                      <div className="Subtitle" style={{ width: '50%', fontSize: 10 }}>
                        {t('betesaReport.adjustmentLevelPage.dominanceQuadrant')} {User.first_name}:
                      </div>
                      <p
                        style={{
                          fontSize: '10px',
                          color: '#98B06F',
                          display: 'inline',
                          textTransform: 'uppercase',
                          width: 'max-content',
                        }}
                      >
                        {t(NameQuadrants(dominationQuadrant) || '').toUpperCase()}
                      </p>
                    </WrapperPriority>
                    {itemWithPriorityOne && (
                      <WrapperPriority>
                        <div className="Subtitle" style={{ fontSize: 10 }}>
                          {t('betesaReport.adjustmentLevelPage.onePriorityQuadrant')}
                        </div>
                        <div style={{ fontSize: 10, marginRight: '12px' }}>
                          {t(NameQuadrants(String(itemWithPriorityOne)) || '').toUpperCase()}
                        </div>
                      </WrapperPriority>
                    )}
                  </ContainerTextTableCenter>
                </BetesaQuadrantsContainer>
              </div>

              <GraphicPolar
                width="180px"
                title={`${t('betesaReport.adjustmentLevelPage.thinkingStructureStyleLegend')} ${User.first_name}`}
                showQuadrantName={false}
                AlertLevel={AlertLevel}
                IdealAlertLevel={ProfileIdealGlobal?.alert_level}
                Profile={QuadrantGlobal}
                userName={User.first_name}
                dominationData={dominationQuadrant}
                fontSize="10px"
                lineGraphic={true}
              />
            </ContainerRow>
          </WrapperText>
          <FooterPage
            name={User?.first_name}
            lastName={User?.last_name}
            page={++currentPage + 1}
            totalPages={totalPages}
            answerDate={answerDate}
            language={lang}
          />
        </Page>
      )}
    </Container>
  )
}
