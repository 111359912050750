import { useEffect, useState } from 'react'
import { MainLayout } from 'views/layouts'

import {
  Container,
  RowContainer,
  RowTestContainer,
  RowTestType,
  Header,
  Body,
  GraphicsContainer,
  SubContainer,
  TypeGraphicContainer,
  CardTest,
  ImageContainer,
  Image,
  TitleContainer,
  CardTestSelected,
  ContainerSelect,
  TitleTest,
} from './styled'
import { P } from 'views/components/UI/Text'
import Select from 'views/components/UI/Select'
import imageBox from 'assets/images/box-icon.png'
import imageBoxBrain from 'assets/images/brain-icon.png'
import ResultsSKills from './pages/resultsSkills'
import ResultsTalents from './pages/resultsTalents'
import { GetDataDevelopmentPlan } from '../../store'
import { fetchDevelopmentPlan } from '../../services/fetch-development-plan'
import { useSelector } from 'react-redux'
import { userSelector } from 'ducks/user'
import { useNotification } from 'lib/context/notification.context'
import useCompanyLogo from 'hooks/cobranding/get-company-logo/get-company-logo-hook'
import CobrandingLogo from 'views/components/UI/Logo/logo-cobranding'
import { cobrandingLogoSelector } from 'ducks/ui'
import { useTranslation } from 'react-i18next'

interface ProcessSelected {
  value: number
  label: string
}

interface Test {
  typeTest: string
  testId: number
  testName: string
  domination: string
  testData: GetList[]
}
interface GetList {
  typeTest: string
  domination: string
}

const Analytics = () => {
  const { t } = useTranslation()
  const { userId } = useSelector(userSelector)
  const [listProcess, setListProcess] = useState<ProcessSelected[]>([])
  const [selectedProcess, setSelectedProcess] = useState<number | ''>('')
  const [tests, setTests] = useState<Test[]>([])
  const { getCompanyLogo } = useCompanyLogo()
  const [hasCalledGetLogo, setHasCalledGetLogo] = useState(false)

  const [selectedTalents, setSelectedTalents] = useState<boolean>(false)
  const [selectedSkills, setSelectedSkills] = useState<boolean>(false)
  const [dataAllProcess, setDataAllProcess] = useState<GetDataDevelopmentPlan[]>([])
  const { getWarning } = useNotification()
  const companyLogo = useSelector(cobrandingLogoSelector)

  const handleSelectChangeProcess = (valueChange: number) => {
    setSelectedProcess(valueChange)
    const processSelected: any = dataAllProcess.find((item: any) => item.processId === valueChange)

    setTests(processSelected.testData)
  }

  const handleSelectSkills = () => {
    setSelectedSkills(true)
    setSelectedTalents(false)
  }

  useEffect(() => {
    setTimeout(() => {
      if (!hasCalledGetLogo && selectedProcess.toString()) {
        setHasCalledGetLogo(true)
        getCompanyLogo({ processId: selectedProcess.toString() })
      }
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyLogo, hasCalledGetLogo])

  useEffect(() => {
    fetchDevelopmentPlan(userId)
      .then((data) => {
        setDataAllProcess(data)
        const dataProcess = data.map((item: any) => {
          if (item.accessReport || item.participantReport) {
            return {
              value: item.processId,
              label: item.nameProcess,
            }
          }
          return undefined
        })
        const filteredDataProcess = dataProcess.filter((item: any) => item !== undefined)
        setListProcess(filteredDataProcess)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          getWarning('No tienes pruebas contestadas aún, para poder ver analíticas')
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  const disabled = tests.some(
    (item) => item.typeTest === 'Betesa Premium' && (item.domination === 'No interpretable' || !item.domination)
  )
  const handleSelectTalents = () => {
    if (disabled) {
      getWarning(t('evaluatedUser.notifications.noInterpretationBetesa'))
    } else {
      setSelectedTalents(true)
      setSelectedSkills(false)
    }
  }

  return (
    <MainLayout cobrandingLogoUrl={companyLogo ?? ''}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {companyLogo && <CobrandingLogo logoUrl={companyLogo} hideMobile />}
      </div>
      <Container>
        <Header>
          <RowContainer>
            <P weight={600} marginRight="20px">
              {t('evaluatedUser.analytics.title')}
            </P>
            <ContainerSelect>
              {listProcess.length > 0 && (
                <Select
                  label={t('global.common.processText')}
                  options={listProcess}
                  value={selectedProcess}
                  onChange={(e) => handleSelectChangeProcess(e as any)}
                  hideNeverChoice
                />
              )}
            </ContainerSelect>
          </RowContainer>
          {!selectedProcess && tests.length ? (
            <h2>Debe seleccionar un proceso</h2>
          ) : (
            <RowTestContainer>
              {tests.length > 0 && <h5>{t('evaluatedUser.analytics.subtitle')}</h5>}
              <RowTestType>
                {selectedTalents === true ? (
                  <CardTestSelected disabled={disabled} onClick={handleSelectTalents}>
                    <ImageContainer>
                      <Image src={imageBoxBrain} />
                    </ImageContainer>
                    <TitleContainer>
                      <TitleTest>Betesa - {t('global.common.betesaTalentsText')}</TitleTest>
                    </TitleContainer>
                  </CardTestSelected>
                ) : (
                  <>
                    {tests.find((item) => item.typeTest === 'Betesa Premium') && (
                      <CardTest disabled={disabled} onClick={handleSelectTalents}>
                        <ImageContainer>
                          <Image src={imageBoxBrain} />
                        </ImageContainer>
                        <TitleContainer>
                          <TitleTest>Betesa - {t('global.common.betesaTalentsText')}</TitleTest>
                        </TitleContainer>
                      </CardTest>
                    )}
                  </>
                )}
                {selectedSkills === true ? (
                  <CardTestSelected onClick={handleSelectSkills}>
                    <ImageContainer>
                      <Image src={imageBox} />
                    </ImageContainer>
                    <TitleContainer>
                      <TitleTest>{t('global.common.skillText')}</TitleTest>
                    </TitleContainer>
                  </CardTestSelected>
                ) : (
                  <>
                    {tests.find((item) => item.typeTest === 'Competencias') && (
                      <CardTest onClick={() => handleSelectSkills()}>
                        <ImageContainer>
                          <Image src={imageBox} />
                        </ImageContainer>
                        <TitleContainer>
                          <TitleTest>{t('global.common.skillText')}</TitleTest>
                        </TitleContainer>
                      </CardTest>
                    )}
                  </>
                )}
              </RowTestType>
            </RowTestContainer>
          )}
        </Header>

        <Body>
          <GraphicsContainer>
            <SubContainer width="100%">
              <TypeGraphicContainer>
                {selectedTalents === true && (
                  <ResultsTalents testId={tests.find((item) => item.typeTest === 'Betesa Premium')?.testId as number} />
                )}
                {selectedSkills === true && (
                  <ResultsSKills
                    userId={userId}
                    testId={tests.find((item) => item.typeTest === 'Competencias')?.testId as number}
                  />
                )}
              </TypeGraphicContainer>
            </SubContainer>
          </GraphicsContainer>
        </Body>
      </Container>
    </MainLayout>
  )
}

export default Analytics
